/* eslint-disable react/no-unescaped-entities */
import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  CustomInput,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import { Flip, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import QRCodeLib from 'react-qr-code';
import { Form } from '@unform/web';
import PixIcon from '@mui/icons-material/Pix';
import { Scope } from '@unform/core';
import { isAfter, isDate, parse } from 'date-fns';

// import Recaptcha from 'react-google-invisible-recaptcha';
import { copyStringToClipboard } from '~/util/copyToClipboard';
import { formatPrice, formatWhatsappPhoneNumber } from '~/util/format';
// import storeIcon from '~/assets/images/store.svg';

import SchemaValidator, {
  cpfSchema,
  emailSchema,
  holderSchema,
} from '~/validators/form/new_pix_link';

import history from '~/services/history';
import Loading from '~/components/Loading';
import {
  finalize,
  loadCart,
  setPaymentData,
} from '~/store/modules/shareable_cart/actions';
// import InputCheckbox from '~/components/Inputs/InputCheckbox';
import InputSelect from '~/components/Inputs/InputSelect';
import InputMask from '~/components/Inputs/InputMask';
import CardFlag from '~/util/CardFlag';
import { installmentsLimited } from '~/util/installmentsList';
import { maskBuilder } from '~/util/maskTelephone';
import { stateList } from '~/util/stateList';
import api from '~/services/api';
import Input from '~/components/Inputs/Input';
// import SelectCustom from '~/components/Inputs/SelectCustom';
import isDarkColor from '~/util/isDarkColor';
import TextAreaWithCounter from '~/components/Inputs/TextAreaWithCounter';
import { useLocalStorage } from '~/hooks/useLocalStorage';
import { getSubscriberIdFromToken } from '~/util/checkout';
import { BnplAccepted, BnplRejected } from '../Bnpl';
import { formatCheckoutError } from './checkoutErrorMapper';

export default function LinkPix() {
  const formRef = useRef(null);
  const toastId = useRef(null);
  // const refCaptcha = useRef(null);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const dispatch = useDispatch();

  const client = useSelector(({ shareable_cart }) => shareable_cart?.client);

  const storeOnlinePaymentLimit = Number(
    useSelector(
      ({ shareable_cart }) =>
        shareable_cart.cart?.store?.online_payments_value_limit
    )
  );

  const freight = useSelector(
    ({ shareable_cart }) => shareable_cart.cart?.freight
  );
  const amount = useSelector(
    ({ shareable_cart }) => shareable_cart.cart?.amount
  );
  const total = Number(amount) + Number(freight);

  const [lastPixFinalizeCallCheckout, setLastPixFinalizeCallCheckout] =
    useLocalStorage('last_pix_finalize_call_checkout', 0);
  const [telephoneV, setTelephone] = useState('');

  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [cityList, setCityList] = useState([]);
  const [searchedCEP, setSearchedCEP] = useState('');
  const [disabled, setDisabled] = useState(false);

  const [noNumber, setNoNumber] = useState(false);

  const [brand, setBrand] = useState(null);
  const [paymentType, setPaymentType] = useState(null);

  const [showBnplComponent, setShowBnplComponent] = useState(null);
  const [bnplData, setBnplData] = useState(null);

  // const [agreement, setAgreement] = useState(null);

  const loading = useSelector(({ shareable_cart }) => shareable_cart.loading);

  const paymentProcess = useSelector(
    ({ shareable_cart }) => shareable_cart?.payment_process
  );

  /*
  const pagarmePublicKey = useSelector(
    ({ shareable_cart }) => shareable_cart.cart?.store?.pagarme_api_public_key
  );
  */

  const paymentGateway = useSelector(
    ({ shareable_cart }) => shareable_cart.cart?.store?.payment_gateway
  );

  const bnplPaymentGateway = useSelector(
    ({ shareable_cart }) => shareable_cart.cart?.store?.bnpl_payment_gateway
  );

  const hasPixKey = useSelector(
    ({ shareable_cart }) => shareable_cart.cart?.store?.has_pix_key
  );

  const cartQrCode = useSelector(
    ({ shareable_cart }) => shareable_cart?.cart?.payment_process?.qrcode
  );

  const [loadingPage, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const token = useQuery().get('token');
  const id = useQuery().get('id');

  const cart = useSelector(({ shareable_cart }) => shareable_cart?.cart);

  const isAllosSubscriber = useMemo(
    () =>
      getSubscriberIdFromToken(token) ===
      'a12e2061-baab-4096-a51a-6485acc8b361',
    [token]
  );

  // test theme colors on components
  // const cart = useSelector(({ shareable_cart }) => ({
  //   ...shareable_cart?.cart,
  //   store: {
  //     ...shareable_cart?.cart?.store,
  //     customize: {
  //       ...shareable_cart?.cart?.store?.customize,
  //       theme_color: '#ffffff',
  //     },
  //   },
  // }));

  const themeIsWhite = cart?.store?.customize?.theme_color === '#ffffff';

  const clientData = useSelector(({ shareable_cart }) => shareable_cart.client);

  const cartAddress = useSelector(
    ({ shareable_cart }) => shareable_cart?.cart?.delivery_address
  );

  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [expirationDate, setExpirationDate] = useState('');

  /* const getCardTokenized = useCallback(
    async cardData => {
      const [cardMonth, cardYear] = cardData.expiration_date.split('/');

      const response = await fetch(
        `https://api.pagar.me/core/v5/tokens?appId=${pagarmePublicKey}`,
        {
          method: 'POST',
          body: JSON.stringify({
            card: {
              number: cardData.card_number.replace(' '),
              holder_name: cardData.holder,
              exp_month: cardMonth,
              exp_year: cardYear.slice(2, 4),
              cvv: cardData.security_code,
            },
            type: 'card',
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const data = await response.json();

      return data.id;
    },
    [pagarmePublicKey]
  ); */

  const isBirthDateValid = value => {
    const parsedDate = isDate(value)
      ? value
      : parse(value, 'dd/MM/yyyy', new Date());

    if (!isDate(parsedDate)) {
      formRef.current.setFieldError('birth_date', 'Data inválida');
      return false;
    }

    if (isAfter(parsedDate, new Date())) {
      formRef.current.setFieldError(
        'birth_date',
        'Data de nascimento não pode ser no futuro'
      );
      return false;
    }

    formRef.current.setFieldError('birth_date', null); // Remove error if valid
    return true;
  };

  useEffect(() => {
    if (cart?.store?.customize?.theme_color) {
      setIsDarkTheme(isDarkColor(cart?.store?.customize?.theme_color)); //
      document.documentElement.style.setProperty(
        '--primary',
        cart?.store?.customize?.theme_color
      );
    }
  }, [cart?.store?.customize?.theme_color]);

  useEffect(() => {
    if (paymentType !== 'Pix') return null;

    const refreshInterval = setInterval(() => {
      dispatch(loadCart(id, token));
    }, 10000);

    return () => clearInterval(refreshInterval);
  }, [paymentType, id, token, dispatch]);

  const validateData = useCallback(
    async rawData => {
      let data = {
        ...rawData,
        email: rawData?.email?.trim(),
        installments: rawData?.installments?.value,
        address: {
          ...rawData.address,
        },
        expiration_date: `${expirationDate.split('/')[0]}/20${
          expirationDate.split('/')[1]
        }`,
      };

      if (data.client_is_payer) {
        data = {
          ...data,
          email: data?.email?.trim(),
          address: {
            additional_information: cartAddress?.additional_information || '',
            cep: cartAddress?.cep || '',
            complement: cartAddress?.complement || '',
            neighborhood: cartAddress?.neighborhood || '',
            number: cartAddress?.number || '',
            street_name: cartAddress?.street_name || '',
            city: cartAddress?.city,
            state: cartAddress?.state,
          },
        };
      }

      const month = data?.expiration_date?.split('/')[0];
      const year = data?.expiration_date?.split('/')[1];

      const verifyData = {
        ...data,
        expiration_date: new Date(
          year,
          month > 0 && month <= 12 ? month : 'null'
        ),
      };

      const maskTelephoneLength = maskBuilder(telephoneV).length - 1;

      formRef.current.setErrors({});

      await SchemaValidator(maskTelephoneLength).validate(verifyData, {
        abortEarly: false,
      });

      if (!data.brand) {
        formRef.current.setFieldError(
          'card_number',
          'Não foi possível identificar a bandeira desse cartão'
        );
        return false;
      }

      return data;
    },
    [cartAddress, expirationDate, telephoneV]
  );

  const cartAvailable = useMemo(() => {
    if (
      (storeOnlinePaymentLimit === 0 || total <= storeOnlinePaymentLimit) &&
      (cart?.store.has_pix_key ||
        cart?.store.has_pagarme_key ||
        cart?.store.has_cielo_merchant_keys ||
        cart?.store.has_mercadopago_key ||
        cart?.store.has_pagseguro_key) &&
      (!cart?.display_payment || !cart?.display_payment.includes('hide-card'))
    ) {
      return true;
    }
    return false;
  }, [storeOnlinePaymentLimit, total, cart]);

  const pixPaymentAvailable = useMemo(() => {
    if (
      (cart?.store.has_pix_key ||
        cart?.store.has_pagarme_key ||
        cart?.store.has_cielo_merchant_keys ||
        cart?.store.has_mercadopago_key ||
        cart?.store.has_pagseguro_key) &&
      (!cart?.display_payment || !cart?.display_payment.includes('hide-pix'))
    ) {
      return true;
    }
    return false;
  }, [cart]);

  const bnplPaymentAvailable = useMemo(() => {
    if (!bnplPaymentGateway) return false;
    if (!cart?.store.has_koin_key) return false;
    if (cart?.display_payment?.includes('hide-bnpl')) return false;
    return true;
  }, [cart, bnplPaymentGateway]);

  const handleSubmit = useCallback(
    async _ => {
      const rawData = formRef?.current?.getData();

      /*
      if (isAllosSubscriber && !agreement) {
        toast.error('você precisa aceitar a política de privacidade da Allos.');
        return;
      }
      */

      setDisabled(true);

      try {
        if (
          (!paymentType && !cartAvailable && !pixPaymentAvailable) ||
          paymentType === 'Bnpl'
        ) {
          const maskTelephoneLength = maskBuilder(telephoneV).length - 1;

          const schema = Yup.object().shape({
            name: Yup.string().required('Insira seu nome'),
            birth_date: Yup.string()
              .required('Insira a data de nascimento')
              .test('is-date-valid', 'Data inválida', value => {
                const parsedDate = parse(value, 'dd/MM/yyyy', new Date());
                return isDate(parsedDate);
              })
              .test(
                'is-not-future-date',
                'Não é possível incluir uma data futura',
                value => {
                  const parsedDate = parse(value, 'dd/MM/yyyy', new Date());
                  return !isAfter(parsedDate, new Date());
                }
              ),
            email: emailSchema,
            cpf: cpfSchema,
            telephone: Yup.string()
              .required('Insira seu telefone')
              .min(maskTelephoneLength, 'Insira todos os dígitos'),
            address: Yup.object().shape({
              cep: Yup.string()
                .required('Insira o CEP corretamente')
                .min(9, 'Insira todos os dígitos do CEP'),
              street_name: Yup.string().required('Insira o nome da rua'),
              number: Yup.string()
                .max(10, 'Número deve ter no máximo 10 caracteres')
                .required('Insira o número'),
              neighborhood: Yup.string().required('Insira o nome do bairro'),
              state: Yup.string().required('Selecione o estado'),
              city: Yup.string().required('Selecione a cidade'),
              complement: Yup.string()
                .notRequired()
                .max(128, 'Máximo de 128 caracteres'),
              additional_information: Yup.string()
                .notRequired()
                .max(
                  128,
                  'As informações adicionais devem ter no máximo 128 caracteres'
                ),
            }),
          });

          await schema.validate(rawData, { abortEarly: false });

          setBnplData(rawData);
          setShowBnplComponent(true);
          return;
        }

        const data = await validateData(rawData);

        if (!data) {
          setDisabled(false);
          return;
        }

        if (cart?.client_finish) {
          await api.patch(
            `/shareable-carts/clients/${
              cart?.client?.id || clientData?.id
            }/address`,
            data.address,
            {
              headers: { 'X-Shareable-Token': token },
            }
          );
        }

        if ((!paymentType && cartAvailable) || paymentType === 'Card') {
          const payments = {
            cielo: 103,
            rede: 111,
            mercadopago: 109,
            pagarme: 108,
            pagarme_hub: 108,
            pagseguro: 113,
          };

          /* if (['pagarme', 'pagarme_hub'].includes(paymentGateway)) {
            const cardToken = await getCardTokenized({
              ...data,
              card_number: data.card_number.replace(/\s/g, ''),
              payment_type: payments[paymentGateway],
            });

            dispatch(
              setPaymentData({
                card_token: cardToken,
                payment_type: payments[paymentGateway],
                installments: data.installments,
                telephone: data.telephone,
                address: data.address,
                cpf: data.cpf,
                email: data.email,
                name: data.name,
              })
            );
          } else { */
          dispatch(
            setPaymentData({
              ...data,
              card_number: data.card_number.replace(/\s/g, ''),
              payment_type: payments[paymentGateway],
            })
          );
          // }
        }

        dispatch(
          finalize({
            page_state: {
              setLoading,
              setError,
              formatCheckoutError,
            },
          })
        );
        setDisabled(false);
      } catch (err) {
        console.error(err);
        if (!(err instanceof Yup.ValidationError)) {
          window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        }

        setDisabled(false);

        const validationErrors = {};

        // console.log(formRef.current.getFieldRef(e.path).scrollHeight);

        if (err instanceof Yup.ValidationError) {
          try {
            const el = formRef.current
              ?.getFieldRef(err.inner[0].path)
              ?.getInputDOMNode();

            el.scrollIntoView({ behavior: 'smooth', block: 'center' });
          } catch (e) {
            const el = formRef.current?.getFieldRef(err.inner[0].path);

            el.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }

          err.inner.forEach(e => {
            validationErrors[e.path] = e.message;
          });
          formRef.current?.setErrors(validationErrors);
          // setErrors(validationErrors);
        }
        if (err.response && err?.response?.data?.message) {
          toast.error(err?.response?.data?.message, {
            autoClose: err?.response?.data?.duration || 5000,
          });
        }
      } /* finally {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      } */
    },
    [
      cart,
      clientData,
      dispatch,
      paymentGateway,
      paymentType,
      cartAvailable,
      token,
      validateData,
      telephoneV,
      pixPaymentAvailable,
      // agreement,
      // isAllosSubscriber,
      // getCardTokenized,
    ]
  );

  useEffect(() => {
    if (token && id) {
      document.title = 'Meu Carrinho | Viddy';

      const root = document.getElementById('root');
      const body = document.querySelector('body');

      root.classList.add('shared-cart-layout');
      root.style.minHeight = '100vh';

      body.classList.add('shared-cart-layout');

      dispatch(loadCart(id, token));
    } else {
      toast.error('Houve um problema na identificação do carrinho!');
      history.push('/');
    }

    return () => {
      const root = document.getElementById('root');
      const body = document.querySelector('body');

      root.classList.remove('shared-cart-layout');
      root.style.minHeight = 'unset';

      body.classList.remove('shared-cart-layout');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PAYMENTS_TYPES = useMemo(
    () => [
      {
        id: 1,
        available: cartAvailable,
        label: 'Cartão de Crédito',
        tag: 'Card',
        icon: 'las la-credit-card',
        col_padding: 'px-0',
        value: false,
        checked: (!paymentType && cartAvailable) || paymentType === 'Card',
      },
      {
        id: 2,
        available: pixPaymentAvailable,
        label: 'Pix',
        tag: 'Pix',
        icon: 'las la-university',
        col_padding: 'px-0',
        value: false,
        checked:
          (!paymentType && !cartAvailable && pixPaymentAvailable) ||
          paymentType === 'Pix',
      },
      {
        id: 3,
        available: bnplPaymentAvailable,
        label: 'Pix Parcelado',
        tag: 'Bnpl',
        icon: 'las la-university',
        col_padding: 'px-0',
        value: false,
        checked:
          (!paymentType && !cartAvailable && !pixPaymentAvailable) ||
          paymentType === 'Bnpl',
      },
    ],
    [cartAvailable, paymentType, pixPaymentAvailable, bnplPaymentAvailable]
  );

  useEffect(() => {
    if (noNumber) {
      formRef?.current?.setFieldValue('address.number', 'sn');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noNumber]);

  useEffect(() => {
    const isPix =
      PAYMENTS_TYPES.find(type => type.checked).tag === 'Pix' ||
      paymentType === 'Pix';
    if (Date.now() < lastPixFinalizeCallCheckout + 5000) return null;
    if (!cart?.payment_process?.transaction_id && isPix && !cartQrCode) {
      setLoading(true);
      const payments = {
        cielo: hasPixKey ? 105 : 112,
        rede: 105,
        mercadopago: 110,
        pagseguro: 114,
        pagarme: 106,
        pagarme_hub: 106,
      };
      dispatch(
        setPaymentData({
          payment_type: payments[paymentGateway],
        })
      );
      dispatch(
        finalize({
          page_state: {
            setLoading,
            setError,
            formatCheckoutError,
          },
        })
      );
      setLastPixFinalizeCallCheckout(Date.now());
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PAYMENTS_TYPES]);

  useEffect(() => {
    const getCities = async () => {
      const { data } = await api.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedState.value}/municipios`
      );

      const parsedCities = data.map(city => ({
        value: city.nome,
        label: city.nome,
      }));

      setCityList(parsedCities);
    };

    if (selectedState) getCities();
  }, [selectedState]);

  const searchCEP = async () => {
    const cep = formRef.current.getFieldValue('address.cep');

    if (searchedCEP === cep) return;
    setSearchedCEP(cep);
    try {
      // toastId.current = toast.info(`Buscando por cep: ${cep}...`, {
      //   autoClose: false,
      // });
      const { data } = await api.get(`cep/${cep}`);

      const formData = formRef.current.getData();

      // toast.update(toastId.current, {
      //   render: 'Endereço encontrado!',
      //   type: toast.TYPE.SUCCESS,
      //   autoClose: 5000,
      //   transition: Flip,
      // });

      formRef.current.setData({
        ...formData,
        address: { ...data },
      });
      const state = stateList.find(s => s.value === data.state);
      if (state) formRef.current.setFieldError('address.state', '');

      setSelectedState(state);
      formRef.current?.setFieldValue('address.state', state);

      const city = {
        label: data.city,
        value: data.city,
      };
      setSelectedCity(city);
      if (city) formRef.current.setFieldError('address.city', '');
      formRef.current?.setFieldValue('address.city', city);
    } catch (err) {
      toast.update(toastId.current, {
        render: 'Endereço não encontrado!',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        transition: Flip,
      });
    }
  };

  if (
    showBnplComponent === null &&
    cart?.payment_process?.payment_method === 'bnpl' &&
    (cart?.payment_process?.status === 'failed' ||
      cart?.payment_process?.reason === 'Rejected' ||
      cart?.payment_process?.applicable === false)
  )
    return (
      <BnplRejected
        onClick={() => setShowBnplComponent(false)}
        isDarkTheme={isDarkTheme}
        themeIsWhite={themeIsWhite}
      />
    );

  if (
    showBnplComponent ||
    (cart?.payment_process?.payment_method === 'bnpl' &&
      cart?.payment_process?.status === 'pending' &&
      cart?.payment_process?.reason !== 'Availability')
  )
    return (
      <BnplAccepted
        setShowBnplComponent={setShowBnplComponent}
        bnplData={bnplData}
        themeIsWhite={themeIsWhite}
        isDarkTheme={isDarkTheme}
      />
    );

  if (loading)
    return (
      <Loading
        customClassName="shareable-cart-loading"
        customColor={cart?.store?.customize?.theme_color}
        color="custom"
      />
    );

  return (
    <Row className="w-100 mx-0 px-3 pb-2 mb-md-4">
      <Container>
        <Card className="card new-layout my-4 w-100">
          <CardBody className="card-body new-layout p-0 d-flex flex-column-reverse flex-lg-row">
            <Col lg={8} className="m-0 p-0">
              <Row className="w-100 mx-0 mb-4">
                <Nav
                  className="w-100"
                  tabs
                  style={{
                    borderBottomWidth: '3px',
                    borderColor:
                      (themeIsWhite && '#000000') ||
                      cart?.store?.customize?.theme_color ||
                      '#2544b1',
                  }}
                >
                  <Row className="w-100 mx-0 justify-content-center">
                    {PAYMENTS_TYPES.filter(type => type.available).map(type => (
                      <Col
                        lg={type.col_width}
                        className={type.col_padding}
                        key={type.id}
                      >
                        <NavItem>
                          <NavLink
                            className={`d-flex flex-row justify-content-center align-items-center w-100 ${
                              type.checked ? 'active' : ''
                            } font-size-14`}
                            style={{
                              backgroundColor:
                                type.checked &&
                                (cart?.store?.customize?.theme_color ||
                                  '#2544b1'),
                              color: type.checked
                                ? (isDarkTheme && 'white') || 'black'
                                : 'black',
                              border: '1px solid #CCCCCC',
                              borderColor: type.checked
                                ? (themeIsWhite && '#000000') ||
                                  cart?.store?.customize?.theme_color ||
                                  '#2544b1'
                                : '#CCCCCC',
                              transition: 'backgroundColor 0.2s ease-in-out',
                            }}
                            onClick={() => setPaymentType(type.tag)}
                            to="#"
                          >
                            {type.tag === 'Bnpl' && (
                              <span className="px-0 py-0 font-size-20 mr-2">
                                <PixIcon fontSize="inherit" />
                              </span>
                            )}
                            {type.tag === 'Pix' && (
                              <span className="px-0 py-0 font-size-20 mr-2">
                                <PixIcon fontSize="inherit" />
                              </span>
                            )}
                            {type.tag !== 'Pix' && type.tag !== 'Bnpl' && (
                              <i className={`${type.icon} fs-30 mr-2`} />
                            )}

                            <span className="text-nowrap">{type.label}</span>
                          </NavLink>
                        </NavItem>
                      </Col>
                    ))}
                  </Row>
                </Nav>
              </Row>
              <Form
                onSubmit={handleSubmit}
                ref={formRef}
                initialData={
                  paymentProcess
                    ? {
                        name: client?.name,
                        email: client?.email,
                        cpf: client?.cpf,
                        telephone: client?.telephone,
                        ...paymentProcess,
                        installments: {
                          label: paymentProcess?.installments || 1,
                          value: paymentProcess?.installments || 1,
                        },
                      }
                    : {
                        installments: {
                          label: 1,
                          value: 1,
                        },
                      }
                }
                className="py-2 px-4 px-lg-2 pb-5 pt-lg-0"
              >
                {((!paymentType && cartAvailable) ||
                  paymentType === 'Card') && (
                  <>
                    {loadingPage ? (
                      <Loading
                        customClassName="loadingpix"
                        message="Processando sua compra, aguarde!"
                        customColor={cart?.store?.customize?.theme_color}
                        color="custom"
                      />
                    ) : (
                      <>
                        <div
                          style={{
                            maxWidth: '540px',
                            margin: '0 auto',
                          }}
                        >
                          <div className="font-size-14 font-weight-600 text-black pb-4">
                            Dados do Cartão
                          </div>
                          <FormGroup className="w-100 d-lg-flex justify-content-center align-items-center">
                            <Col xs={12} align="end" className="text-left px-0">
                              <Label className="font-weight-400 font-size-14 mr-3 my-1">
                                Nome impresso*
                              </Label>
                              <Input
                                classRest="link-pix-input"
                                type="text"
                                name="holder"
                                placeholder="Nome"
                                autoCapitalize="characters"
                                onBlur={async e => {
                                  try {
                                    await holderSchema.validate(e.target.value);
                                  } catch (err) {
                                    formRef.current.setFieldError(
                                      'holder',
                                      err.message
                                    );
                                  }
                                }}
                              />
                            </Col>
                          </FormGroup>
                          <Row className="w-100 mx-0 mb-4">
                            <FormGroup className="mb-4 w-100 d-lg-flex justify-content-center align-items-center">
                              <Col
                                xs={12}
                                align="end"
                                className="text-left px-0"
                              >
                                <Label className="font-weight-400 font-size-14 mr-3 my-1">
                                  Número do Cartão*
                                </Label>
                                <Col className="px-0 mx-0">
                                  <InputMask
                                    classes="link-pix-input pr-0"
                                    name="card_number"
                                    placeholder="0000 0000 0000 0000"
                                    mask="9999 9999 9999 9999"
                                    maskChar={null}
                                    type="tel"
                                    onChange={e => {
                                      const flag = CardFlag(e.target.value);
                                      if (flag) {
                                        setBrand(flag);
                                      }
                                      if (e.target.value.length === 19)
                                        document
                                          .querySelector('#expiration_date')
                                          .focus();
                                    }}
                                    onBlur={e => {
                                      if (!CardFlag(e.target.value)) {
                                        formRef.current.setFieldError(
                                          'card_number',
                                          'Não foi possível identificar a bandeira desse cartão'
                                        );
                                      }
                                    }}
                                  />
                                </Col>
                              </Col>
                            </FormGroup>

                            <FormGroup className="w-100 mb-4 d-lg-flex justify-content-center align-items-center">
                              <Row className="mx-0 w-100">
                                <Col
                                  xs={12}
                                  sm={4}
                                  align="end"
                                  className="text-left pl-0 mb-4 mb-sm-0"
                                >
                                  <Label className="font-weight-400 font-size-14 mr-3 my-1">
                                    Validade*
                                  </Label>
                                  <InputMask
                                    id="expiration_date"
                                    classes="link-pix-input mr-1"
                                    name="expiration_date"
                                    placeholder="MM/AA"
                                    isCheckout
                                    type="tel"
                                    mask="99/99"
                                    formatChars={{
                                      3: '[0-3]',
                                      9: '[0-9]',
                                      4: '[2-3]',
                                    }}
                                    value={expirationDate}
                                    onChange={e => {
                                      setExpirationDate(e.target.value);
                                      if (e.target.value.length === 5)
                                        document
                                          .querySelector('#security_code')
                                          .focus();
                                    }}
                                    onBlur={async e => {
                                      const [month, year] =
                                        e.target.value.split('/');
                                      const yearSufix = String(
                                        new Date().getFullYear()
                                      ).slice(2, 4);

                                      if (Number(month) > 12)
                                        formRef.current.setFieldError(
                                          'expiration_date',
                                          'Mês inválido'
                                        );
                                      if (Number(year) < yearSufix)
                                        formRef.current.setFieldError(
                                          'expiration_date',
                                          'Ano inválido'
                                        );
                                    }}
                                    maskChar={null}
                                  />
                                </Col>
                                <Col
                                  xs={6}
                                  sm={4}
                                  align="end"
                                  className="text-left px-0 px-sm-2"
                                >
                                  <Label className="font-weight-400 font-size-14 mr-3 my-1">
                                    CVV*
                                  </Label>
                                  <InputMask
                                    classes="link-pix-input"
                                    id="security_code"
                                    name="security_code"
                                    placeholder="CVV"
                                    isCheckout
                                    type="text"
                                    mask="9999"
                                    maskChar={null}
                                  />
                                </Col>
                                <Col
                                  xs={6}
                                  sm={4}
                                  className="text-left padding-adjusts-label pr-0"
                                >
                                  <Label className="d-block font-weight-400 font-size-14 my-1">
                                    Parcelas*
                                  </Label>
                                  <InputSelect
                                    classRest="link-pix-input"
                                    name="installments"
                                    isCheckout
                                    options={installmentsLimited(
                                      cart?.max_installments || 12
                                    )}
                                    placeholder="Selecione"
                                    defaultValue={{
                                      value: cart?.max_installments,
                                      label: String(cart?.max_installments),
                                    }}
                                    singlePath
                                    customSelect
                                  />
                                </Col>
                              </Row>
                            </FormGroup>

                            <div className="font-size-14 font-weight-600 text-black py-3">
                              Dados de Cobrança
                            </div>
                            <Row className="mx-0 w-100">
                              <Col xs={12} className="text-left px-0">
                                <FormGroup className="w-100 mb-3">
                                  <Label className="font-weight-400 font-size-14 my-1 text-nowrap">
                                    Nome completo*
                                  </Label>
                                  <Input
                                    name="name"
                                    placeholder="Nome"
                                    type="text"
                                    isCheckout
                                    classRest="link-pix-input"
                                  />
                                </FormGroup>
                              </Col>
                              <Col
                                xs={12}
                                sm={6}
                                align="end"
                                className="text-left px-0 pr-sm-2"
                              >
                                <FormGroup className="w-100 mb-4">
                                  <Label className="font-weight-400 font-size-14 mr-3 my-1">
                                    Whatsapp*
                                  </Label>
                                  <InputMask
                                    mask={maskBuilder(telephoneV)}
                                    classes="link-pix-input"
                                    maskChar={null}
                                    value={telephoneV}
                                    name="telephone"
                                    placeholder="Whatsapp"
                                    isCheckout
                                    type="tel"
                                    onChange={e => {
                                      if (
                                        e.target.value.length < 15 ||
                                        e.target.value[5] === '9'
                                      )
                                        setTelephone(e.target.value);
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col
                                xs={12}
                                sm={6}
                                align="end"
                                className="text-left px-0 pl-sm-2"
                              >
                                <FormGroup className="w-100 mb-4">
                                  <Label className="font-weight-400 font-size-14 mr-3 my-1">
                                    CPF*
                                  </Label>
                                  <InputMask
                                    name="cpf"
                                    classes="link-pix-input"
                                    type="tel"
                                    placeholder="CPF"
                                    mask="999.999.999-99"
                                    isCheckout
                                    maskChar={null}
                                    onBlur={async e => {
                                      try {
                                        await cpfSchema.validate(
                                          e.target.value
                                        );
                                      } catch (err) {
                                        formRef.current.setFieldError(
                                          'cpf',
                                          err.message
                                        );
                                      }
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Col
                              xs={12}
                              sm={6}
                              align="end"
                              className="text-left px-0 pr-sm-2"
                            >
                              <FormGroup className="w-100 mb-4">
                                <Label className="font-weight-400 font-size-14 mr-3 my-1">
                                  Data de Nascimento*
                                </Label>
                                <InputMask
                                  name="birth_date"
                                  placeholder="Data de Nascimento"
                                  mask="99/99/9999"
                                  maskChar={null}
                                  classes="link-pix-input"
                                  onBlur={e => {
                                    isBirthDateValid(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col
                              xs={12}
                              sm={6}
                              align="end"
                              className="text-left px-0 pl-sm-2"
                            >
                              <FormGroup className="w-100 mb-4">
                                <Label className="font-weight-400 font-size-14 mr-3 my-1">
                                  E-mail*
                                </Label>
                                <Input
                                  name="email"
                                  placeholder="E-mail"
                                  type="text"
                                  classRest="link-pix-input"
                                  onBlur={async e => {
                                    try {
                                      await emailSchema.validate(
                                        e.target.value
                                      );
                                    } catch (err) {
                                      formRef.current.setFieldError(
                                        'email',
                                        err.message
                                      );
                                    }
                                  }}
                                />
                              </FormGroup>
                            </Col>

                            <Scope path="address">
                              <Row className="w-100 mx-0">
                                <Row className="w-100 mx-0 mt-2">
                                  <Card className="card border shadow-none mb-4 w-100">
                                    <CardBody className="card-body border px-3 py-3">
                                      <p>
                                        Para compras com cartão é necessário
                                        informar seu endereço de cobrança por
                                        questões de segurança
                                      </p>
                                      <Row className="w-100 mx-0">
                                        <Col className="px-0">
                                          <FormGroup className="w-100 d-lg-flex justify-content-center align-items-center">
                                            <Col
                                              xs={12}
                                              align="end"
                                              className="text-left px-0"
                                            >
                                              <Label className="font-weight-400 font-size-14 mr-3 my-1">
                                                CEP*
                                              </Label>
                                              <InputMask
                                                name="cep"
                                                placeholder="CEP"
                                                mask="99999-999"
                                                isCheckout
                                                type="tel"
                                                classes="link-pix-input"
                                                maskChar={null}
                                                onChange={e =>
                                                  e.target.value.length === 9 &&
                                                  searchCEP()
                                                }
                                              />
                                            </Col>
                                          </FormGroup>
                                          {searchedCEP && (
                                            <>
                                              <Row className="w-100 mx-0 mt-lg-2">
                                                <Col
                                                  xs={12}
                                                  lg={6}
                                                  className="px-0 pr-lg-2"
                                                >
                                                  <FormGroup className="w-100 mb-4 d-lg-flex justify-content-center align-items-center">
                                                    <Col
                                                      xs={12}
                                                      align="end"
                                                      className="text-left px-0"
                                                    >
                                                      <Label className="font-weight-400 font-size-14 mr-3 my-1">
                                                        Endereço*
                                                      </Label>
                                                      <Input
                                                        name="street_name"
                                                        isCheckout
                                                        placeholder="Endereço"
                                                        classRest="link-pix-input"
                                                        type="text"
                                                      />
                                                    </Col>
                                                  </FormGroup>
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  lg={6}
                                                  className="px-0 pl-lg-2"
                                                >
                                                  <FormGroup className="w-100 mb-4 d-lg-flex justify-content-center align-items-center">
                                                    <Col
                                                      xs={12}
                                                      align="end"
                                                      className="text-left px-0"
                                                    >
                                                      <Row className="mx-0 d-flex justify-content-between align-items-center">
                                                        <Label className="font-weight-400 font-size-14 mr-3 my-1">
                                                          Número*
                                                        </Label>
                                                        <CustomInput
                                                          type="switch"
                                                          id="noNumberSwitch"
                                                          name="noNumber"
                                                          label="Sem Número"
                                                          checked={noNumber}
                                                          className="text-primary text-center font-weight-400 my-1 mt-lg-0"
                                                          onChange={() =>
                                                            setNoNumber(
                                                              !noNumber
                                                            )
                                                          }
                                                        />
                                                      </Row>
                                                      <Input
                                                        name="number"
                                                        classRest="link-pix-input"
                                                        isCheckout
                                                        placeholder="Nº"
                                                        type="tel"
                                                        disabled={noNumber}
                                                      />
                                                    </Col>
                                                  </FormGroup>
                                                </Col>
                                                <FormGroup className="w-100 mb-4 d-lg-flex justify-content-center align-items-center">
                                                  <Col
                                                    xs={12}
                                                    align="end"
                                                    className="text-left px-0 mt-3 mt-lg-0"
                                                  >
                                                    <Label className="font-weight-400 font-size-14 mr-3 my-1 mt-2 mt-lg-0 ">
                                                      Bairro
                                                    </Label>
                                                    <Input
                                                      name="neighborhood"
                                                      classRest="link-pix-input"
                                                      placeholder="Bairro"
                                                      isCheckout
                                                      type="text"
                                                    />
                                                  </Col>
                                                </FormGroup>
                                              </Row>
                                              <Row className="w-100 mx-0 mt-lg-2">
                                                <FormGroup className="w-100 mb-4 d-lg-flex justify-content-center align-items-center">
                                                  <Col
                                                    xs={12}
                                                    align="end"
                                                    className="text-left px-0"
                                                  >
                                                    <TextAreaWithCounter
                                                      name="complement"
                                                      classRest="link-pix-input"
                                                      classNames={{
                                                        formGroup: 'mb-0',
                                                      }}
                                                      placeholder="Complemento"
                                                      title="Complemento"
                                                      maxSize={128}
                                                      isCheckout
                                                    />
                                                  </Col>
                                                </FormGroup>
                                              </Row>
                                              <Row className="w-100 mx-0 mt-lg-2">
                                                <Col
                                                  xs={12}
                                                  lg={6}
                                                  align="end"
                                                  className="text-left px-0 pr-lg-2"
                                                >
                                                  <FormGroup className="w-100 mb-4">
                                                    <Label className="font-weight-400 font-size-14 mr-3 my-1">
                                                      Estado
                                                    </Label>
                                                    <InputSelect
                                                      name="state"
                                                      className="link-pix-input"
                                                      options={stateList}
                                                      onChange={selectedOption => {
                                                        setSelectedState(
                                                          selectedOption
                                                        );
                                                      }}
                                                      value={selectedState}
                                                      placeholder="Estado"
                                                      isCheckout
                                                    />
                                                  </FormGroup>
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  lg={6}
                                                  align="end"
                                                  className="text-left px-0 pl-lg-2"
                                                >
                                                  <FormGroup className="w-100 mb-4">
                                                    <Label className="font-weight-400 font-size-14 mr-3 my-1">
                                                      Cidade
                                                    </Label>
                                                    <InputSelect
                                                      name="city"
                                                      className="link-pix-input"
                                                      options={cityList}
                                                      onChange={selectedOption => {
                                                        setSelectedCity(
                                                          selectedOption
                                                        );
                                                      }}
                                                      value={selectedCity}
                                                      placeholder="Cidade"
                                                      isDisabled={
                                                        !selectedState
                                                      }
                                                      isCheckout
                                                    />
                                                  </FormGroup>
                                                </Col>
                                              </Row>
                                            </>
                                          )}
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </Row>
                              </Row>
                            </Scope>
                          </Row>

                          <Row className="w-100 mx-0 mb-2 justify-content-center">
                            <FormGroup className="d-none">
                              <Input
                                type="text"
                                name="brand"
                                value={brand || ''}
                                readOnly
                              />
                            </FormGroup>

                            {/* <Recaptcha
                                    ref={refCaptcha}
                                    sitekey={
                                      process.env.REACT_APP_RECAPTCHA_KEY
                                    }
                                    onResolved={}
                                  /> */}
                            <Button
                              id="submit-button"
                              color="white"
                              className=" px-5 py-3 my-3 btn-pix-details"
                              type="submit"
                              style={{
                                backgroundColor:
                                  cart?.store?.customize?.theme_color,
                                color: isDarkTheme ? 'white' : 'black',
                                border: themeIsWhite && '1px solid #000000',
                              }}
                              disabled={disabled}
                            >
                              <span className="mx-2 font-size-16 font-weight-700 color-white">
                                Concluir pedido
                              </span>
                            </Button>
                          </Row>
                          {isAllosSubscriber && (
                            <Row className="justify-content-center">
                              <span
                                id="agreement-checkbox-container"
                                className="text-center mx-auto font-size-14 text-secondary"
                                style={{ width: '60%', fontWeight: '500' }}
                              >
                                Ao clicar em Concluir Pedido, você aceita a{' '}
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href="https://privacy-central.securiti.ai/#/notices/9e8a13f5-37c6-4176-ab8e-42ffb0a7234a"
                                  className="text-decoration-underline cursor-pointer"
                                >
                                  política de privacidade{' '}
                                </a>
                                da Allos.
                              </span>
                            </Row>
                          )}
                          <Row className="justify-content-center">
                            <span className="text-center">
                              * Campos obrigatórios
                            </span>
                          </Row>
                          {error && (
                            <Row className="justify-content-center">
                              <span className="text-center text-danger">
                                {error}
                              </span>
                            </Row>
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
                {((!paymentType && !cartAvailable && pixPaymentAvailable) ||
                  paymentType === 'Pix') && (
                  <div>
                    {loadingPage ? (
                      <Loading
                        customClassName="loadingpix"
                        message="Gerando pix, aguarde!"
                        customColor={cart?.store?.customize?.theme_color}
                        color="custom"
                      />
                    ) : (
                      <div
                        style={{
                          maxWidth: '540px',
                          margin: '0 auto',
                        }}
                      >
                        <div className="font-size-14 font-weight-600 text-black pb-4">
                          Realize seu pagamento no Pix!
                        </div>
                        <Row>
                          <Col
                            xs={12}
                            className="d-flex justify-content-center justify-content-lg-start mb-3 mb-lg-0"
                          >
                            <QRCodeLib
                              value={cart?.payment_process?.qrcode || ''}
                              size={200}
                              className="mx-auto mb-4"
                            />
                          </Col>
                          <Col
                            xs={12}
                            className="d-flex flex-column justify-content-start"
                          >
                            <Input
                              name="pix-link"
                              placeholder="Link do Pix"
                              type="text"
                              className="mb-2 mx-2 mx-sm-3"
                              value={cart?.payment_process?.qrcode || ''}
                              disabled
                              multiline
                              style={{
                                height: '5rem',
                                maxHeight: 'none',
                                resize: 'none',
                              }}
                            />
                            <Button
                              id="copyPixData2"
                              tip="Copiar"
                              forceTip
                              tipPlacement="left"
                              color="white"
                              className="py-2 my-3 btn-pix-details"
                              style={{
                                backgroundColor:
                                  cart?.store?.customize?.theme_color,
                                color: isDarkTheme ? 'white' : 'black',
                                border: themeIsWhite && '1px solid #000000',
                              }}
                              onClick={() => {
                                copyStringToClipboard(
                                  cart?.payment_process?.qrcode || '',
                                  'Link Pix copiado com sucesso!'
                                );
                              }}
                            >
                              <span className="ml-2 font-size-16 font-weight-700 color-white">
                                Copiar código PIX
                              </span>
                            </Button>
                          </Col>
                        </Row>

                        <div className="w-100 mx-0 my-4 py-3 d-flex align-items-center">
                          <Col
                            className="px-0 mb-2 mb-lg-0 circle-step"
                            style={{
                              border: `3px solid #2544b1`,
                              borderColor:
                                (themeIsWhite && '#000000') ||
                                cart?.store?.customize?.theme_color ||
                                '#2544b1',
                              color:
                                (themeIsWhite && '#000000') ||
                                cart?.store?.customize?.theme_color ||
                                '#2544b1',
                            }}
                          >
                            1
                          </Col>
                          <Col className="pl-2 font-size-14 font-weight-600 text-gray-600 px-0">
                            Abra o app ou banco de sua preferência. Escolha a
                            opção pagar com Pix "copia e cola", ou código QR.
                          </Col>
                        </div>
                        <div className="w-100 mx-0 my-4 py-3 d-flex align-items-center">
                          <Col
                            className="px-0 mb-2 mb-lg-0 circle-step"
                            style={{
                              border: `3px solid #2544b1`,
                              borderColor:
                                (themeIsWhite && '#000000') ||
                                cart?.store?.customize?.theme_color ||
                                '#2544b1',
                              color:
                                (themeIsWhite && '#000000') ||
                                cart?.store?.customize?.theme_color ||
                                '#2544b1',
                            }}
                          >
                            2
                          </Col>
                          <Col className="pl-2 font-size-14 font-weight-600 text-gray-600 px-0">
                            Copie e cole o código, ou escaneie o código QR com a
                            câmera do seu celular. Confira todas as informações
                            e autorize o pagamento.
                          </Col>
                        </div>
                        <div className="w-100 mx-0 mt-4 pt-3 d-flex align-items-center">
                          <Col
                            className="px-0 mb-2 mb-lg-0 circle-step"
                            style={{
                              border: `3px solid #2544b1`,
                              borderColor:
                                (themeIsWhite && '#000000') ||
                                cart?.store?.customize?.theme_color ||
                                '#2544b1',
                              color:
                                (themeIsWhite && '#000000') ||
                                cart?.store?.customize?.theme_color ||
                                '#2544b1',
                            }}
                          >
                            3
                          </Col>
                          <Col className="pl-2 font-size-14 font-weight-600 text-gray-600 px-0">
                            Você vai receber a confirmação do pagamento por
                            e-mail ou WhatsApp.
                          </Col>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {((!paymentType && !cartAvailable && !pixPaymentAvailable) ||
                  paymentType === 'Bnpl') && (
                  <>
                    {loadingPage ? (
                      <Loading
                        customClassName="loadingpix"
                        message="Processando sua compra, aguarde!"
                        customColor={cart?.store?.customize?.theme_color}
                        color="custom"
                      />
                    ) : (
                      <>
                        <div
                          style={{
                            maxWidth: '540px',
                            margin: '0 auto',
                          }}
                        >
                          <div className="font-size-14 font-weight-600 text-black pb-3">
                            Dados de Cobrança
                          </div>
                          <Row className="mx-0 w-100">
                            <Col xs={12} className="text-left px-0">
                              <FormGroup className="w-100 mb-3">
                                <Label className="font-weight-400 font-size-14 my-1 text-nowrap">
                                  Nome completo*
                                </Label>
                                <Input
                                  name="name"
                                  placeholder="Nome"
                                  type="text"
                                  isCheckout
                                  classRest="link-pix-input"
                                />
                              </FormGroup>
                            </Col>
                            <Col
                              xs={12}
                              sm={6}
                              align="end"
                              className="text-left px-0 pr-sm-2"
                            >
                              <FormGroup className="w-100 mb-4">
                                <Label className="font-weight-400 font-size-14 mr-3 my-1">
                                  Whatsapp*
                                </Label>
                                <InputMask
                                  mask={maskBuilder(telephoneV)}
                                  classes="link-pix-input"
                                  maskChar={null}
                                  value={telephoneV}
                                  name="telephone"
                                  placeholder="Whatsapp"
                                  isCheckout
                                  type="tel"
                                  onChange={e => {
                                    if (
                                      e.target.value.length < 15 ||
                                      e.target.value[5] === '9'
                                    )
                                      setTelephone(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col
                              xs={12}
                              sm={6}
                              align="end"
                              className="text-left px-0 pl-sm-2"
                            >
                              <FormGroup className="w-100 mb-4">
                                <Label className="font-weight-400 font-size-14 mr-3 my-1">
                                  CPF*
                                </Label>
                                <InputMask
                                  name="cpf"
                                  classes="link-pix-input"
                                  type="tel"
                                  placeholder="CPF"
                                  mask="999.999.999-99"
                                  isCheckout
                                  maskChar={null}
                                  onBlur={async e => {
                                    try {
                                      await cpfSchema.validate(e.target.value);
                                    } catch (err) {
                                      formRef.current.setFieldError(
                                        'cpf',
                                        err.message
                                      );
                                    }
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <FormGroup className="w-100 mb-4 d-sm-flex justify-content-center align-items-center">
                            <Col
                              xs={12}
                              sm={6}
                              align="end"
                              className="text-left px-0 pr-sm-2 mb-4 mb-sm-0"
                            >
                              <Label className="font-weight-400 font-size-14 mr-3 my-0">
                                Data de Nascimento*
                              </Label>

                              <InputMask
                                name="birth_date"
                                placeholder="Data de Nascimento"
                                mask="99/99/9999"
                                maskChar={null}
                                classes="link-pix-input"
                                onBlur={e => {
                                  isBirthDateValid(e.target.value);
                                }}
                              />
                            </Col>
                            <Col
                              xs={12}
                              sm={6}
                              align="end"
                              className="text-left px-0 pl-sm-2"
                            >
                              <Label className="font-weight-400 font-size-14 mr-3 my-0">
                                E-mail*
                              </Label>
                              <Input
                                name="email"
                                placeholder="E-mail"
                                type="text"
                                classRest="link-pix-input"
                                onBlur={async e => {
                                  try {
                                    await emailSchema.validate(e.target.value);
                                  } catch (err) {
                                    formRef.current.setFieldError(
                                      'email',
                                      err.message
                                    );
                                  }
                                }}
                              />
                            </Col>
                          </FormGroup>

                          <Scope path="address">
                            <Row className="w-100 mx-0">
                              <Row className="w-100 mx-0 mt-2">
                                <Card className="card border shadow-none mb-4 w-100">
                                  <CardBody className="card-body border px-3 py-3">
                                    <Row className="w-100 mx-0">
                                      <Col className="px-0">
                                        <FormGroup className="w-100 d-lg-flex justify-content-center align-items-center">
                                          <Col
                                            xs={12}
                                            align="end"
                                            className="text-left px-0"
                                          >
                                            <Label className="font-weight-400 font-size-14 mr-3 my-1">
                                              CEP*
                                            </Label>
                                            <InputMask
                                              name="cep"
                                              placeholder="CEP"
                                              mask="99999-999"
                                              isCheckout
                                              type="tel"
                                              classes="link-pix-input"
                                              maskChar={null}
                                              onChange={e =>
                                                e.target.value.length === 9 &&
                                                searchCEP()
                                              }
                                            />
                                          </Col>
                                        </FormGroup>
                                        {searchedCEP && (
                                          <>
                                            <Row className="w-100 mx-0 mt-lg-2">
                                              <Col
                                                xs={12}
                                                lg={6}
                                                className="px-0 pr-lg-2"
                                              >
                                                <FormGroup className="w-100 mb-4 d-lg-flex justify-content-center align-items-center">
                                                  <Col
                                                    xs={12}
                                                    align="end"
                                                    className="text-left px-0"
                                                  >
                                                    <Label className="font-weight-400 font-size-14 mr-3 my-1">
                                                      Endereço*
                                                    </Label>
                                                    <Input
                                                      name="street_name"
                                                      isCheckout
                                                      placeholder="Endereço"
                                                      classRest="link-pix-input"
                                                      type="text"
                                                    />
                                                  </Col>
                                                </FormGroup>
                                              </Col>
                                              <Col
                                                xs={12}
                                                lg={6}
                                                className="px-0 pl-lg-2"
                                              >
                                                <FormGroup className="w-100 mb-0 d-lg-flex justify-content-center align-items-center">
                                                  <Col
                                                    xs={12}
                                                    align="end"
                                                    className="text-left px-0"
                                                  >
                                                    <Row className="mx-0 d-flex justify-content-between align-items-center">
                                                      <Label className="font-weight-400 font-size-14 mr-3 my-1">
                                                        Número*
                                                      </Label>
                                                      <CustomInput
                                                        type="switch"
                                                        id="noNumberSwitch"
                                                        name="noNumber"
                                                        label="Sem Número"
                                                        checked={noNumber}
                                                        className="text-primary text-center font-weight-400 my-1 mt-lg-0"
                                                        onChange={() =>
                                                          setNoNumber(!noNumber)
                                                        }
                                                      />
                                                    </Row>
                                                    <Input
                                                      name="number"
                                                      classRest="link-pix-input"
                                                      isCheckout
                                                      placeholder="Nº"
                                                      type="tel"
                                                      disabled={noNumber}
                                                    />
                                                  </Col>
                                                </FormGroup>
                                              </Col>
                                              <FormGroup className="w-100 mb-4 d-lg-flex justify-content-center align-items-center">
                                                <Col
                                                  xs={12}
                                                  align="end"
                                                  className="text-left px-0 mt-3 mt-lg-0"
                                                >
                                                  <Label className="font-weight-400 font-size-14 mr-3 my-1 mt-2 mt-lg-0 ">
                                                    Bairro
                                                  </Label>
                                                  <Input
                                                    name="neighborhood"
                                                    classRest="link-pix-input"
                                                    placeholder="Bairro"
                                                    isCheckout
                                                    type="text"
                                                  />
                                                </Col>
                                              </FormGroup>
                                            </Row>
                                            <Row className="w-100 mx-0 mt-lg-2">
                                              <FormGroup className="w-100 mb-4 d-lg-flex justify-content-center align-items-center">
                                                <Col
                                                  xs={12}
                                                  align="end"
                                                  className="text-left px-0"
                                                >
                                                  <TextAreaWithCounter
                                                    name="complement"
                                                    classRest="link-pix-input"
                                                    classNames={{
                                                      formGroup: 'mb-0',
                                                    }}
                                                    placeholder="Complemento"
                                                    title="Complemento"
                                                    maxSize={128}
                                                    isCheckout
                                                  />
                                                </Col>
                                              </FormGroup>
                                            </Row>
                                            <Row className="w-100 mx-0 mt-lg-2">
                                              <Col
                                                xs={12}
                                                lg={6}
                                                align="end"
                                                className="text-left px-0 pr-lg-2"
                                              >
                                                <FormGroup className="w-100 mb-4">
                                                  <Label className="font-weight-400 font-size-14 mr-3 my-1">
                                                    Estado
                                                  </Label>
                                                  <InputSelect
                                                    name="state"
                                                    className="link-pix-input"
                                                    options={stateList}
                                                    onChange={selectedOption => {
                                                      setSelectedState(
                                                        selectedOption
                                                      );
                                                    }}
                                                    value={selectedState}
                                                    placeholder="Estado"
                                                    isCheckout
                                                  />
                                                </FormGroup>
                                              </Col>
                                              <Col
                                                xs={12}
                                                lg={6}
                                                align="end"
                                                className="text-left px-0 pl-lg-2"
                                              >
                                                <FormGroup className="w-100 mb-4">
                                                  <Label className="font-weight-400 font-size-14 mr-3 my-1">
                                                    Cidade
                                                  </Label>
                                                  <InputSelect
                                                    name="city"
                                                    className="link-pix-input"
                                                    options={cityList}
                                                    onChange={selectedOption => {
                                                      setSelectedCity(
                                                        selectedOption
                                                      );
                                                    }}
                                                    value={selectedCity}
                                                    placeholder="Cidade"
                                                    isDisabled={!selectedState}
                                                    isCheckout
                                                  />
                                                </FormGroup>
                                              </Col>
                                            </Row>
                                          </>
                                        )}
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Row>
                            </Row>
                          </Scope>
                        </div>

                        <Row className="w-100 mx-0 mb-2 justify-content-center">
                          <FormGroup className="d-none">
                            <Input
                              type="text"
                              name="brand"
                              value={brand || ''}
                              readOnly
                            />
                          </FormGroup>

                          {/* <Recaptcha
                                  ref={refCaptcha}
                                  sitekey={
                                    process.env.REACT_APP_RECAPTCHA_KEY
                                  }
                                  onResolved={}
                                /> */}
                          <Button
                            id="submit-button"
                            color="white"
                            className=" px-5 py-3 my-3 btn-pix-details"
                            type="submit"
                            style={{
                              backgroundColor:
                                cart?.store?.customize?.theme_color,
                              color: isDarkTheme ? 'white' : 'black',
                              border: themeIsWhite && '1px solid #000000',
                            }}
                            disabled={disabled}
                          >
                            <span className="mx-2 font-size-16 font-weight-700 color-white">
                              Concluir pedido
                            </span>
                          </Button>
                        </Row>
                        <Row className="justify-content-center">
                          <span className="text-center">
                            * Campos obrigatórios
                          </span>
                        </Row>
                        {error && (
                          <Row className="justify-content-center">
                            <span className="text-center text-danger">
                              {error}
                            </span>
                          </Row>
                        )}
                      </>
                    )}
                  </>
                )}
              </Form>
            </Col>

            <Col className="px-0 col-lg-4 background-order-details">
              <Card className="card new-layout mb-4 w-100 bg-transparent shadow-none">
                <CardBody className="card-body new-layout px-0 px-lg-4 py-0">
                  <Row className="w-100 mx-0 pb-2 mb-md-4 mb-3">
                    <Card className="card new-layout mb-4 w-100">
                      <CardBody className="card-body new-layout px-3 py-3">
                        <div className="font-size-16 font-weight-400 text-black pb-4 d-flex justify-content-between w-100">
                          <span>Resumo do pedido</span>{' '}
                          <span className="font-weight-bold">
                            #{cart?.order_number}
                          </span>
                        </div>
                        <Row className="mx-0">
                          <span className="font-size-14 font-weight-400 text-gray d-block">
                            Valor dos Produtos
                          </span>
                          <span className="font-size-14 font-weight-400 text-black ml-auto">
                            {formatPrice(Number(cart?.amount))}
                          </span>
                        </Row>
                        {cart?.is_for_delivery && (
                          <Row className="mx-0 mt-2">
                            <span className="font-size-14 font-weight-400 text-gray d-block">
                              Valor do Frete
                            </span>
                            <span className="font-size-14 font-weight-400 text-black ml-auto">
                              {formatPrice(cart?.freight_user || 0)}
                            </span>
                          </Row>
                        )}
                        {cart?.giftback?.amount && cart.giftback.amount > 0 && (
                          <Row className="mx-0 mt-2">
                            <span className="font-size-14 font-weight-400 text-gray d-block">
                              Desconto Giftback
                            </span>
                            <span className="font-size-14 font-weight-400 text-red ml-auto">
                              -{formatPrice(cart?.giftback?.amount || 0)}
                            </span>
                          </Row>
                        )}
                        <hr />
                        <Row className="mx-0 mt-2">
                          <span className="font-size-14 font-weight-400 text-gray d-block">
                            Valor Total
                          </span>
                          <span className="font-size-14 font-weight-400 text-black ml-auto">
                            {formatPrice(
                              Number(cart?.amount) +
                                Number(cart?.freight_user || 0) -
                                (cart?.giftback?.amount &&
                                cart.giftback.amount > 0
                                  ? Number(cart.giftback.amount)
                                  : 0)
                            )}
                          </span>
                        </Row>
                      </CardBody>
                    </Card>
                    {cart?.is_for_delivery ? (
                      cart?.client && (
                        <Card className="card new-layout mb-4 w-100">
                          <CardBody className="card-body new-layout px-3 py-3">
                            <p className="font-size-12 font-weight-400 text-gray d-block mb-1">
                              Loja
                            </p>
                            <p className="font-size-14 font-weight-400 text-black">
                              {cart?.store?.fantasy_name}
                            </p>
                            <p className="font-size-12 font-weight-400 text-gray d-block mb-1">
                              Vendedor
                            </p>
                            <p className="font-size-14 font-weight-400 text-black">
                              {cart?.handled_by?.name} -{' '}
                              <a
                                className="font-size-14 font-weight-400 text-black"
                                href={`https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
                                  cart?.handled_by?.telephone
                                )}`}
                              >
                                {cart?.handled_by?.telephone}
                              </a>
                            </p>
                            <hr />
                            <p className="font-size-12 font-weight-400 text-gray d-block mb-1">
                              Cliente
                            </p>
                            <p className="font-size-14 font-weight-400 text-black mb-1">
                              {cart?.client?.name}
                            </p>
                            <p className="font-size-14 font-weight-400 text-black">
                              <a
                                className="font-size-14 font-weight-400 text-black"
                                href={`https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
                                  cart?.client?.telephone
                                )}`}
                              >
                                {cart?.client?.telephone}
                              </a>
                            </p>
                            <hr />
                            {cart?.delivery_address && (
                              <>
                                <p className="font-size-12 font-weight-400 text-gray d-block mb-1">
                                  Endereço de entrega
                                </p>
                                <p className="font-size-14 font-weight-400 text-black">
                                  {cart?.delivery_address?.street_name},{' '}
                                  {cart?.delivery_address?.number} <br />
                                  {cart?.delivery_address?.neighborhood} -{' '}
                                  {cart?.delivery_address?.city} -{' '}
                                  {cart?.delivery_address?.state}
                                </p>
                              </>
                            )}
                          </CardBody>
                        </Card>
                      )
                    ) : (
                      <>
                        <Card className="card new-layout mb-4 w-100">
                          <CardBody className="card-body new-layout px-3 py-3">
                            <span className="font-size-12 font-weight-400 text-gray d-block">
                              Endereço de retirada
                            </span>
                            <a
                              href={`https://www.google.com/maps/dir/?api=1&destination=${cart?.store?.address?.lat},${cart?.store?.address?.long}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="font-size-14 font-weight-400 text-black">
                                {cart?.store?.address?.street_name} ,{' '}
                                {cart?.store?.address?.number} <br />
                                {cart?.store?.address?.neighborhood} -{' '}
                                {cart?.store?.address?.city} -{' '}
                                {cart?.store?.address?.state}
                              </span>
                            </a>

                            {/* <Row className="py-2 align-items-center">
                                     <Col
                                      xs={12}
                                      lg={3}
                                      className="px-0 pl-lg-2 mt-2 mt-lg-0 justify-content-center align-content-center d-flex"
                                    >
                                      <img
                                        src={storeIcon}
                                        alt="store"
                                        height={32}
                                      />
                                    </Col>
                                    <Col
                                      xs={12}
                                      lg={12}
                                      className="pl-lg-2 mt-2 mt-lg-0"
                                    >

                                    </Col>
                                  </Row> */}
                          </CardBody>
                        </Card>
                        {cart?.client && (
                          <Card className="card new-layout mb-4 w-100">
                            <CardBody className="card-body new-layout px-3 py-3">
                              <span className="font-size-12 font-weight-400 text-gray d-block">
                                Cliente
                              </span>
                              <span className="font-size-14 font-weight-400 text-black">
                                {cart?.client?.name} <br />
                                <a
                                  href={`https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
                                    cart?.client?.telephone
                                  )}`}
                                  className="font-size-14 font-weight-400 text-black d-flex"
                                >
                                  {cart?.client?.telephone}
                                </a>
                              </span>
                            </CardBody>
                          </Card>
                        )}
                      </>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </CardBody>
        </Card>
      </Container>
    </Row>
  );
}
