import React, { useRef, useState, useMemo } from 'react';
import { Form } from '@unform/web';
import {
  Alert,
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import api from '~/services/api';
import Input from '~/components/Inputs/Input';
import { getRole } from '~/util/getRole';

export default function CancelCartModal({ sale, setData }) {
  const formRef = useRef(null);
  const roles = useSelector(({ user }) => user.profile.roles);
  const [disabled, setDisabled] = useState(false);

  const [show, setShow] = useState(false);

  const role = useMemo(
    () =>
      getRole(roles, [
        'digital-consultant',
        'cross-manager',
        'salesman',
        'store-manager',
        'financier',
      ]),
    [roles]
  );

  const hideButtonSaleCross = useMemo(
    () =>
      (sale?.status >= 2 && sale.is_cross && role === 'digital-consultant') ||
      (sale.is_cross &&
        sale.status_text === 'Aguardando aceite' &&
        role === 'cross-manager'),
    [sale, role]
  );

  const hideCancelClearSale =
    sale.status_text === 'Análise de Fraude' ||
    sale.status_text === 'Pedido em Processamento' ||
    sale.status_text === 'Análise Reprovada';

  const isRefund =
    sale?.status >= 2 &&
    ['Paid', '1', 'CONCLUIDA'].includes(sale?.payment_process?.status);

  const isChangeBack =
    sale?.status === 0 &&
    sale.status_text === 'Pagamento rejeitado pelo anti-fraude' &&
    sale.store.payment_gateway === 'cielo';

  const handleCancel = async data => {
    setDisabled(true);
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        cancellation_reason: Yup.string().required(
          'Insira o motivo do cancelamento'
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const {
        data: { cart },
      } = await api.patch(`${getRole(roles)}/carts/${sale.id}/cancel`, data);

      setData(cart);
      setShow(false);
      toast.success('Venda cancelada!');
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    } finally {
      setDisabled(false);
    }
  };
  return (
    <>
      {!hideButtonSaleCross && !hideCancelClearSale && (
        <Button
          color="danger"
          className="new-layout-btn main ml-lg-2 mb-2 mb-lg-0 px-5"
          onClick={e => {
            e.stopPropagation();
            setShow(true);
          }}
        >
          Cancelar
        </Button>
      )}
      <Modal isOpen={show}>
        <ModalHeader toggle={() => setShow(false)}>
          Deseja mesmo cancelar a venda?
        </ModalHeader>
        <Form onSubmit={handleCancel} ref={formRef}>
          <ModalBody>
            <h6>
              <strong>Número do pedido: {sale.order_number}.</strong>
            </h6>
            <h5>
              O pedido {sale.client ? 'de ' : ''}
              <strong>{sale.client?.name}</strong> será cancelado.
            </h5>
            {isRefund && (
              <Alert className="my-4" color="info">
                Ao cancelar este pedido, o pagamento será estornado.
              </Alert>
            )}
            {isChangeBack && (
              <Alert className="my-4" color="danger">
                Essa transação ainda pode ser autorizada, procure o seu gerente
                para fazer a avaliação, se confirmar o cancelamento, será feito
                o estorno do limite para o seu cliente.
              </Alert>
            )}
            <FormGroup className="mt-3 mb-0">
              <Input
                name="cancellation_reason"
                placeholder="Motivo do cancelamento"
                type="text"
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              className="new-layout-btn main"
              type="submit"
              color="danger"
              disabled={disabled}
            >
              Confirmar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}
